dmx.Actions({
  's3.upload': function (options) {
    var inp = this.parse(options.input);
    var url = this.parse(options.url);
    var file = document.getElementById(inp).files[0];

    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();

      xhr.onerror = reject;
      xhr.onabort = reject;
      xhr.ontimeout = reject;
      xhr.onload = resolve;

      xhr.open('PUT', url);
      xhr.setRequestHeader('Content-Type', file.type);
      xhr.send(file);
    });
  },
});
